.quotaCheckingModal {

  :global(.modal-dialog) {
    min-width: 1000px;
  }

  .tableWrapper {
    width: auto;
  }

}

.quotaCheckModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

 & > p {
  & > a {
    text-decoration: underline;
   }
 }
}

.quotaInfoMessage {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: flex-start;
  justify-content: start;

  .quotaInfoMessage_icon {
    margin-top: 3px;
  }
}

.quotaInfoContainer {
  display: flex;
  flex-direction: column;
  & > h4 {
    padding: .5rem 0;
  }
}

.quotaInfo_content {
  display: flex;
  flex-direction: row;
  gap: .5rem;
}

.quotaInfo_warning {

  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
  justify-content: center;


  .warning_icon {
    color: var(--error-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
  


  .quotaInfo_warning_title {
    color: var(--error-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    font-weight: 500;

    .quotaInfo_warning_icon {
      display: flex;

    }

  }
  

}

.quotaInfo {
  display: flex;
  gap: .5rem;
}

.quotaWarningContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.quotaWarning {
  background-color: var(--error-light);
  padding: .5rem;
  border-radius: 4px;
  border: 1px solid var(--error-medium-light);
  margin-bottom: .5rem;
}



.quotaInfo_title, .quotaWarning_title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-headline);
}

.green {
  color: var(--success-primary);
}

.red {
  color: var(--error-primary);
}

.yellow {
  color: var(--warning-primary-dark);
}