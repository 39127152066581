.label {
  display: block;
  margin-bottom: .4rem;
}

.enable2FAContainer {
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;

  & > ul {
    margin: 1rem;
    list-style-type: circle;
    list-style-position: inside;
    & > li {
      list-style-position: inside;
      list-style: decimal;

      :global(input#code) {
        margin-top: 1.5rem;
      }

      & a {
        color: var(--active);
      }
    }
  }

  .QRCodeContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.successMessage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
  background-color: var(--success-light);
  color: var(--success-medium);

  span {
    display: flex;
    align-items: center;
    color: var(--color2);

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}


.errorMessage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
  color: var(--color2);
  background-color: rgba(#ee6c74, .4);

  span {
    display: flex;
    align-items: center;
    color: var(--color2);

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}

.codeForm {
  max-width: 550px;
  margin: 25px 0;
  
  input {
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
    font-size: 50px;
    border: solid 1px var(--clr-gray300);
    // box-shadow: 0 0 5px #ccc inset;
    box-shadow: var(--box-shadow);
    outline: none;
    width: 11%;
    transition: all .2s ease-in-out;
    border-radius: 3px;
    
    &:focus {
      border-color: var(--info-light);
      box-shadow: 0 0 5px var(--info-light) inset;
    }
    
    &::selection {
      background: transparent;
    }
  }
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: var(--secondary-color);
  width: 100%;

  .cardHeader {
    & > p {
      font-size: .9rem;
      margin-top: .5rem;
    }

    & > h5 {
      font-weight: 500;
      color: var(--clr-gray600);
    }

    .workspaceStatus {
      font-weight: 600;
      margin-bottom: 0;
      font-size: 1.2rem;
      color: var( --text-headline);
      margin-top: 1rem;
    }
  }

  .cardContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 5rem;
    margin-top: 1.5rem;
  }

  .cardFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1.5rem;
    width: 100%;

    .trialInfo, .trialInfoWarning {
      display: inline-block;
      padding: .3rem .5rem;
      border-radius: 4px;
      font-size: .8rem;
      font-weight: 500;
      background-color: var(--clr-gray200);
      color: var(--clr-gray500);
    }

    .trialInfoWarning {
      background-color: var(--error-light);
      color: var(--error-primary);
    }
  }

}

.statusBar {
  width: 100%;

  .statusBarHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    & > h4 {
      font-weight: 500;
    }

    .tooltipIcon {
      color: var(--clr-gray350);
      position: relative;
      margin-left: .3rem;
      margin-top: .1rem;

    }

    .statusBarHeaderRight {
      font-size: .9rem;
    }
  }

  .quotaBarLegend {
    margin-top: .8rem;
    font-size: .9rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .quotaBarFail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--error-primary-dark);
      background-color: var(--error-light);
      padding: 1rem;
      font-size: 1.1rem;
    }
   
  }

  progress {
    width: 100%;
    height: 15px;
    border-radius: 25px;
    background-color: var(--clr-gray300);
    overflow: hidden;

  }

  .progressGreen {
    background-color: var(--success-primary);

    &::-webkit-progress-bar {
      background-color: var(--clr-gray300);
      border-radius: 25px;
    }


    &::-webkit-progress-value {
      background-color: var(--success-primary);
      border-radius: 25px;
    }
  
    &::-moz-progress-bar {
      background-color: var(--success-primary);
      border-radius: 25px;
    }
  
    &::-ms-fill {
      background-color: var(--success-primary);
      border-radius: 25px;
    }

  }

  .progressOrange {
    background-color: var(--warning-primary);

    &::-webkit-progress-bar {
      background-color: var(--clr-gray300);
      border-radius: 25px;
    }


    &::-webkit-progress-value {
      background-color: var(--warning-primary);
      border-radius: 25px;
    }
  
    &::-moz-progress-bar {
      background-color: var(--warning-primary);
      border-radius: 25px;
    }
  
    &::-ms-fill {
      background-color: var(--warning-primary);
      border-radius: 25px;
    }
  }

  .progressRed {
    background-color: var(--error-primary);
    
    &::-webkit-progress-bar {
      background-color: var(--clr-gray300);
      border-radius: 25px;
    }


    &::-webkit-progress-value {
      background-color: var(--error-primary);
      border-radius: 25px;
    }
  
    &::-moz-progress-bar {
      background-color: var(--error-primary);
      border-radius: 25px;
    }
  
    &::-ms-fill {
      background-color: var(--error-primary);
      border-radius: 25px;
    }
  }

  
  
}

@media only screen and (max-width: 1220px) {
  .cardWrapper {
    .cardContent {
      flex-direction: column;
      gap: 2rem;
      align-items: flex-start;
    }
  }
  
}




