@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300&family=Inter:wght@200;300;400;500;600;700&display=swap');

:root {
  /* --main-color: #DD2F6E; */
  --main-color: #151419;
  --secondary-color: #fffffe;
  --body-background: #f4f3f9;
  --active: #3d6edc;
  --text-headline: #001e1d;
  --text-paragraph: #AAAFC8;
  --clr-gray100: #f9fbff;
  --clr-gray150: #f4f6fb;
  --clr-gray200: #eef1f6;
  --clr-gray300: #e1e5ee;
  --clr-gray350: #9EA1B4;
  --clr-gray400: #767b91;
  --clr-gray500: #4f546c;
  --clr-gray600: #2a324b;
  --clr-gray700: #161d34;

  --color0: #2463eb;
  --color0l: #e9effd;
  --color1: #40b870;
  --color1l: #ecf8f0;
  --color2: #dc2625;
  --color2l: #fce9e9;
  --color3: #8825eb;
  --color3l: #f3e9fe;
  --stroke: var(--color0);
  --background: var(--color0l);
  --hover: #eff6ff;
  --box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
  --error-primary: #EF4444;
  --error-primary-dark: #6d0000;
  --error-light: #FEE2E2;
  --error-medium-light: #ffb3b3;
  --success-primary: #41B871;
  --success-medium: #067647;
  --success-primary-dark: #0A3D2F;
  --success-medium-border: rgba(6, 118, 71, 0.35);
  --success-light: #D1FAE5;
  --success-medium-light: #ABEFC6;
  --warning-primary: #F5D90A;
  --warning-primary-dark: #ddc306;
  --warning-medium: #B54708;
  --warning-medium-border: rgba(181, 71, 8, 0.35);
  --warning-light: #FFFBD1;
  --info-light: #DBEAFE;
  --info-medium-light: #B2DDFF;
  --info-primary: #3b82f6;
  --info-primary-dark: #00368d;
  --info-medium-border: rgba(23, 92, 211, 0.35);
  --info-medium: #175cd3;
  --purple-light: #F4F3FF;
  --purple-medium: #D9D6FE;
  --purple-medium-border: rgba(89, 37, 220, 0.35);
  --purple-primary: #5925DC;
  --pink-light: #FDF2FA;
  --pink-medium: #FCCEEE;
  --pink-medium-border: rgba(193, 21, 116, 0.35);
  --pink-primary: #C11574;
  --yellow-light: #FCFCDC;
  --green-light: #DCFDDB;
  --blue-light: #DCFAFC;
  --violet-light: #EDEFFF;
  --lilac-light: #F5DCFD;
  --orange-light: #FFE4DE;
  --cyan-medium-border: rgba(9, 169, 169, 0.35);
  --cyan-medium: #09A9A9;



}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

blockquote{
  font-size: 1.4em;
  width:60%;
  margin:25px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-left:6px solid var(--info-primary) ;
  line-height:1.6;
  position: relative;
  background: var(--clr-gray100);
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color: var(--info-primary);
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color:#333333;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}

input,
textarea,
button {
  font-family: inherit;
}

a {
  color: var(--clr-gray500);
}

a:hover {
  color: var(--clr-gray400);
}

a.button {
  color: var(--active);
  border: 1px solid var(--active);
  padding: 7px 14px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: auto;
  background: var(--clr-gray100);
  color: var(--clr-gray500);
  font-family: Inter, 'Lexend Deca', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-headline);
}

.login-form-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: var(--secondary-color);
  padding-top: 2rem;
  border-radius: 4px;

  box-shadow: 0 2px 10px var(--clr-gray300);
}

.login-form-wrapper h1 {
  text-align: center;
  padding: 0 0 20px 0;
  box-sizing: border-box;
}

.login-form-wrapper form {
  padding: 0 40px 40px;
}

.login-form-wrapper form .txt_field {
  position: relative;
  border-bottom: 1px solid var(--main-color);
  margin: 30px 0;
}

.login-form-wrapper h1 {
  color: var(--main-color);
}

.login-form-wrapper .codeForm {
  max-width: 550px;
  margin: 25px 0;
  
  input {
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
    font-size: 30px;
    border: solid 1px var(--clr-gray300);
    box-shadow: var(--box-shadow);
    outline: none;
    width: 11%;
    transition: all .2s ease-in-out;
    border-radius: 3px;
    
    &:focus {
      border-color: var(--info-light);
      box-shadow: 0 0 5px var(--info-light) inset;
    }
    
    &::selection {
      background: transparent;
    }
  }
}

.errorMessage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
  color: var(--color2);
  background-color: rgba(#ee6c74, .4);

  span {
    display: flex;
    align-items: center;
    color: var(--color2);

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}

.txt_field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: var(--clr-gray400);
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.txt_field span::before {
  content: ' ';
  position: absolute;
  top: 40px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--main-color);
}

.input_filed:valid {
  background: var(--secondary-color);
}

.input_filed:focus ~ label,
.input_filed:not(:placeholder-shown).input_filed:not(:focus) ~ label {
  top: -5px;
  color: var(--main-color);
}

input[type='submit'] {
  border: 1px solid;

  /* font-size: 1rem;
  padding: 12px 24px; */
  background: var(--active);
  color: var(--secondary-color);

  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
}

input[type='submit']:hover {
  /* border-color: var(--clr-gray300); */
  transition: 0.5s;
}

.login-form-wrapper input[type='submit'] {
  width: 100%;
  height: 4rem;
  margin-top: 2rem;
  font-size: 1.3rem;
}

.sidebar {
  width: 345px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--secondary-color);
  z-index: 200;
  transition: width 300ms;
  box-shadow: 0 2px 10px var(--clr-gray300);
}

.sidebar-brand {
  height: 80px;
  padding-left: 1rem;
  color: var(--active);
  display: flex;
  align-items: center;
}

.sidebar-brand h1 {
  /* margin-top: 1.5rem; */
  display: flex;
  color: var(--active);
}

.sidebar-brand span {
  display: inline-block;
  padding-left: 1rem;
}

.sidebar-brand .short {
  display: none;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 104px);
  margin-top: 24px;
}

.sidebar-menu li {
  width: 100%;
  padding: 0 1rem;
}

.sidebar-menu a,
.sidebar-menu button {
  display: flex;
  color: var(--clr-gray500);
  font-size: 1.2rem;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: none;
  border: none;
  cursor: pointer;

  .iconWrapper {
    .threatCounter {
      display: none;
    }
  }
}

.sidebar-menu a span:first-child,
.sidebar-menu button span:first-child {
  font-size: 1.5rem;
  padding-right: 1rem;
}

.sidebar-menu a.active {
  color: var(--active);
  background-color: var(--hover);
  border-radius: 4px;
  transition: background 0.7s ease, color 0.7s ease;
}

.sidebar-menu a:hover {
  color: var(--active);
  transition: color 200ms ease;
}

.main-content {
  margin-left: 345px;
}

header {
  /* display: flex; */
  justify-content: space-between;
  padding: 0rem 3rem;
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); */
  position: fixed;
  left: 345px;
  width: calc(100% - 345px);
  /* top: 0; */
  z-index: 100;
  /* background: var(--secondary-color); */
  align-items: center;
  height: 80px;
  transition: width 300ms, left 300ms;
  background: #FBFBFC;
}

header .page-header {
  display: flex;
  padding: 1rem 0rem;
  background: #FBFBFC;
}

header h2 {
  color: #222;
  text-transform: capitalize;
}
header label span {
  font-size: 1.7rem;
  padding-right: 1rem;
  cursor: pointer;
}

main {
  margin-top: 60px;
  padding: 1.5rem 3rem;
  background: #FBFBFC;
  min-height: calc(100vh - 80px);

  position: absolute;
  left: 345px;
  width: calc(100% - 345px);
  transition: width 300ms, left 300ms;
}

main.sudo {
  margin-top: 160px;
}

#nav-toggle {
  display: none;
}

#nav-toggle:checked + .sidebar {
  width: 72px;
}

#nav-toggle:checked + .sidebar .sidebar-menu a {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-left: 0;

  .iconWrapper {
    display: inline-flex;
    position: relative;
    padding-right: 0!important;

    .threatCounter {
      display: inline-block;
      position: absolute;
      margin: 0 .7rem;
      left: 1px;
      top: -3px;
      border-radius: 9px;
      background-color: var(--color0);
      color: var(--secondary-color);
      font-size: .6rem;
      font-weight: 500;
      text-align: center;
      padding: 4px 6px;
    }
  
    // .iconIndicator {
    //   display: inline-block;
    //   position: absolute;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background-color: var(--color0);
    //   right: 1px;
    //   top: 3px;
    // }
  }
}

#nav-toggle:checked + .sidebar .sidebar-brand {
  width: fit-content;
  margin: auto;
  padding-left: 0;
}
#nav-toggle:checked + .sidebar .sidebar-brand span {
  padding-left: 0;
}

#nav-toggle:checked + .sidebar .sidebar-brand h1 .short {
  display: inline;
}

#nav-toggle:checked + .sidebar .sidebar-brand h1 .full,
#nav-toggle:checked + .sidebar .sidebar-menu li a span:last-child,
#nav-toggle:checked + .sidebar .sidebar-menu button span:last-child {
  display: none;
}

#nav-toggle:checked + .sidebar li {
  padding: 0 1rem;
}

#nav-toggle:checked ~ main,
#nav-toggle:checked ~ .main-content header {
  left: 72px;
  width: calc(100% - 72px);
}

.dashboard .cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.dashboard .card {
  display: flex;
  justify-content: space-between;
  background: var(--secondary-color);
  color: var(--main-color);
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.card h1 {
  color: var(--main-color);
}

.card span {
  color: var(--main-color);
}

.card .icon span {
  font-size: 3rem;
}

.list-content {
  position: relative;
}

.list-content .card {
  background: var(--secondary-color);
  color: var(--main-color);

  margin-bottom: 1rem;

  border: 1px solid var(--clr-gray200);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  overflow: hidden;
}

@media print {
  .list-content .card {
    filter: opacity(1);
    -webkit-filter:opacity(1);
  }
}

.search-wrapper {
  margin-bottom: 2rem;
}

.card .card-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-total {
    font-weight: 600;
    font-size: 1.1rem;
  }
}

.card .card-header .buttons {
  display: flex;
}

.next-card-header {
  display: grid;
  grid-gap: 20px;
  margin-bottom: 16px;
}

.next-card-header .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 1.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  border: 1px solid #e5e7eb;

  &:last-of-type {
    border-bottom: none;
  }
}

thead tr {
  /* border-top: 1px solid var(--body-background);
  border-bottom: 2px solid var(--body-background); */

  //border-top: 1px solid #f0f0f0;
  //border-bottom: 2px solid #f0f0f0;
  border: none;
}

td {
  padding: 14px 16px;
  color: var(--main-color);
  vertical-align: top;
}

thead td {
  font-weight: 700;
  padding: 1rem;
}

td:first-child {
  width: 1%;
}

td.link {
  width: 2rem;
  font-size: 1.4rem;
  padding: 1rem 0.4rem;
}

td.link a.button {
  font-size: 19px;
  padding: 4px 11px;
  vertical-align: middle;
  color: var(--secondary-color);
  background-color: var(--active);

  &:hover {
    color: var(--active);
    background-color: var(--secondary-color);
  }
}

table.messages, table.sources {
  display: block;
  overflow-x: auto;
}

//.card-body:has(table.messages) {
.card-body {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 16px;

    // fixes inconsistent safari linear-gradient.
    background: linear-gradient(to left, rgb(#fff, 0), #fff);
    z-index: 2;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 12px;

    background: linear-gradient(
      to right,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 8.17%) 20%,
      rgb(255 255 255 / 23.2%) 33.33%,
      rgb(255 255 255 / 96.4%) 86.67%,
      #fff 100%
    );
    z-index: 1;
    pointer-events: none;
  }
}

.card-body:has(.circlechart) {
  &::before, &::after {
    display: none;
  }
}

.card-body:has(.trendchart) {
  &::before, &::after {
    display: none;
  }
}

table.messages tr.new {
  font-weight: 600;
}

table.messages.selectable tbody tr:hover,
table.messages tbody tr.selected {
  background: rgb(61 110 220 / 10%);
}

table.messages.selectable thead td {
  padding: 1rem;
}

// table.messages td {
//   width: 1%;
// }



table.messages td.message{
  width: 100%;
}

.ellipsis {
  position: relative;
  cursor: pointer;
}

.ellipsis.active {
  position: relative;
  cursor: pointer;
}
.ellipsis.active:before {
  content: '&nbsp;';
  visibility: hidden;
}
.ellipsis.active span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1380px) {
  .sidebar {
    width: 72px;
  }

  .sidebar .sidebar-brand {
    width: fit-content;
    margin: auto;
    padding-left: 0;
  }

  .sidebar .sidebar-brand span {
    padding-left: 0;
  }

  .sidebar .sidebar-brand h1 img.short {
    display: inline;
  }

  .sidebar .sidebar-menu a {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-left: 0;
  }

  .sidebar .sidebar-brand h1 .full,
  .sidebar .sidebar-menu li a span:last-child,
  .sidebar .sidebar-menu button span:last-child {
    display: none;
  }

  .sidebar li {
    padding: 0 1rem;
  }

  main,
  .main-content header {
    left: 72px;
    width: calc(100% - 72px);
  }

  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  #nav-toggle:checked + .sidebar {
    left: -100% !important;
  }
  #nav-toggle:not(:checked) + .sidebar {
    width: 345px;
  }

  .sidebar .sidebar-menu a {
    justify-content: flex-start;
    padding-left: 1rem;
  }

  #nav-toggle:not(:checked) + .sidebar .sidebar-brand h1 img.short {
    display: none;
  }

  #nav-toggle:not(:checked) + .sidebar .sidebar-brand h1 .full,
  #nav-toggle:not(:checked) + .sidebar .sidebar-menu li a span:last-child,
  #nav-toggle:not(:checked) + .sidebar .sidebar-menu button span:last-child {
    display: inline;
  }

  header {
    left: 0 !important;
    width: 100% !important;
  }

  main,
  .main-content header {
    left: 0px !important;
    width: 100% !important;
  }

  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.page-header {
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-between;
  align-items: center;
}

.page-header .controls {
  display: flex;
  margin: 0 1rem;
  justify-content: space-between;
  gap: 1rem;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
}
.controls__export svg {
  margin-right: 5px;
  width: 12px;
  height: 12px;
}

.breadcrumb {
  font-size: 1rem;
}

.breadcrumb span {
  margin-right: 0.4rem;
}

.breadcrumb span + span::before {
  content: '/\00a0';
  padding: 8px;
}

.form-wrapper {
  /* display: flex; */
  justify-content: space-between;
  background: var(--secondary-color);
  color: var(--main-color);
  padding: 2rem 1rem 1rem 1rem;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.form-wrapper .card {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  background: var(--secondary-color);
  color: var(--main-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--clr-gray200);
}

.form-element {
  margin-bottom: 1.5rem;
}

.input-button {
  height: 2.95rem;

  &:hover {
    border: 1px solid var(--active);
    color: var(--active);
    background-color: var(--secondary-color);
  }
}

.share-input {
  font-size: 1rem!important;
}

.form-element input[type='text'],
.form-element input[type='password'],
.form-element textarea,
.form-element input[type='email'],
.form-element input[type='number'],
.form-element input[type='url'] {
  width: 100%;
  height: 3rem;
  font-size: 1.3rem;
  padding: 0.5rem;
  border: 1px solid var(--clr-gray300);
}

.form-element input[type='submit'] {
  font-size: 1rem;
  padding: 12px 24px;
  background: var(--active);
  color: var(--secondary-color);

  &:hover {
    background-color: var(--secondary-color);
    color: var(--color0);
  }
}

.form-element textarea {
  height: 100px;
}

.form-element .submit-wrapper {
  margin-top: 2rem;
}

.form-element.error .react-select__control {
  border: 1px solid var(--color2) !important;
}

.form-element.error input[type='text'],
.form-element.error input[type='number'],
.form-element.error input[type='password'],
.form-element.error input[type='email'],
.form-element.error textarea,
.form-element.error input[type='submit'] {
  border: 1px solid var(--color2);
}

.form-element .error-message {
  color: var(--color2);
  padding-top: 1rem;
}

.select-settings #settings-agregation-select, .select-settings #settings-view-select, .select-settings #settings-breakdown-select {
  width: 100%;
  height: 1rem;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--clr-gray300);}

button {
  padding: 7px 14px;
  border: 1px solid var(--active);
  background: var(--active);
  border-radius: 5px;
  color: var(--secondary-color);
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  font-family: inherit;

  display: flex;
  align-items: center;
  gap: 0.4rem;
}

button:disabled {
  background: rgb(61 110 220 / 50%) !important;
  border: rgb(61 110 220 / 50%);
  color: #fff !important;
  cursor: default !important;
}

button.secondary {
  background: var(--secondary-color);
  color: var(--active);
  border: 1px solid var(--active);
}

button i {
}

.btn-primary {
  background-color: var(--active);
  border: 1px solid transparent;
  color: #fff;
  transition: background-color 250ms ease, opacity 250ms ease;

  &:disabled {
    background-color: var(--active);
    color: #fff;
    opacity: 0.5;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid transparent;
    }
  }

  &:hover {
    background-color: #fff;
    color: var(--active);
    border: 1px solid var(--active);

    svg {
      color: var(--active);
    }
  }
}

.btn-secondary {
}

.btn-success {
}

.btn-danger {
  background: var(--color2);
  color: #fff;
  transition: background-color 250ms ease, opacity 250ms ease;
  border: 1px solid transparent;

  &:disabled {
    background-color: var(--color2) !important;
    color: #fff;
    opacity: 0.5;
  }

  &:hover {
    color: var(--color2);
    background-color: #fff;
    border: solid 1px var(--color2);
  }
}

.btn-reset {
  background-color: var(--clr-gray300);
  color: inherit;
  border: none;

  &:hover {
    color: var(--secondary-color);
    background-color: var(--clr-gray400);
  }
}

.btn-warning {
}

.btn-info {
}

.btn-light {
}

.btn-dark {
}

.btn-link {
}

.react-select__multi-value {
  font-size: 1.2rem;
  padding: 0.4rem;
  border-radius: 0;
}

.react-select__control {
  border-radius: 0px !important;
  border: 1px solid var(--clr-gray300) !important;
}

.text-nowrap {
  white-space: nowrap;
}

.share-layout header {
  left: 0;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
}
.share-layout main {
  left: 0;
  width: 100%;
}

.new-button,
a.new-button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 6px;
  padding: 6px 12px;

  cursor: pointer;
  outline: none;
  font-size: 1rem;

  color: #fff;
  background-color: #2563eb;
  border-radius: 4px;
  transition: color 250ms ease, background-color 250ms ease;


  &:hover {
    color: #2563eb;
    background-color: #fff;
  }
}

.p0 {
  padding: 0 !important;
}

.tooltip {
  z-index: 100;
  padding: 1.2rem;
  position: absolute;

  color: #A0A3A6;

  background: #2C2C2E;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  opacity: 1;
}

.react-datepicker {
  position: absolute;
}

.custom-datepicker {
  border-color: var(--clr-gray300);
  margin-top: .5rem;
  z-index: 30;

  .react-datepicker__header {
    background-color: var(--secondary-color);
    border-bottom: none;
  }

  .react-datepicker__current-month {
    color: var(--clr-gray500);
    font-weight: 400;
  }

  .react-datepicker__day-name {
    color: var(--clr-gray400);
  }
}

.native-lang__menu.css-26l3qy-menu {
  z-index: 41;
}

// HIGHLIGHTS NER

.ner-highlight.active {
  border-radius: 4px;
  padding: 0 .1rem;
  display: inline-block;

  &[data-ner-type='Organization'] {
    background-color: #F0E4FE;
    border: 1px solid #C997F9;
    position: relative;

    &::before {
      content: url('./components/CollapsibleText/assets/organization.svg');
      display: inline-block;
      margin-right: 2px;
    }
  }

  &[data-ner-type='Location'] {
    background-color: #FDDEDE;
    border: 1px solid #F48D8E;

    &::before {
      content: url('./components/CollapsibleText/assets/location.svg');
      display: inline-block;
      margin-right: 2px;
    }
  }

  &[data-ner-type='Person'] {
    background-color: #E7FAC3;
    border: 1px solid #ACDF5B;

    &::before {
      content: url('./components/CollapsibleText/assets/persona.svg');
      display: inline-block;
      margin-right: 2px;
    }

  }
  &[data-ner-type='Military_unit'] {
    background-color: #C9F6E2;
    border: 1px solid #6ED3B2;

    &::before {
      content: url('./components/CollapsibleText/assets/military-unit.svg');
      display: inline-block;
      margin-right: 2px;
    }
  }
}

.ner-highlight {
  &[data-ner-type='Organization'], [data-ner-type='Location'], &[data-ner-type='Person'] {
    background-color: transparent;
    border: none;
    margin: 0;

    &::before {
      content: '';
      margin-right: 0;
    }
  }
}

.keywords-highlight.active {
  background-color: #D7E8FD;
  padding: 0 .1rem 0 0;
  border-radius: 4px;
  border: 1px solid #88B2F8;
  display: inline-block;

  &::before {
    content: url('./components/CollapsibleText/assets/keyword.svg');
    display: inline-block;
    margin-left: .1rem;
  }

  &[data-ner-type='Organization'], [data-ner-type='Location'], &[data-ner-type='Person'] {
    background-color: transparent;
    border: none;
    margin: 0;

    &::before {
      content: '';
      margin-right: 0;
    }
  }
}

.keywords-highlight {
  background-color: transparent;
  border: transparent;
}


// sentiment bar

.sentiment-loader-container {
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sentiment-bar-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  font-size: 1.1rem;

  .sentiment-bar-message_text, .sentiment-bar-message_text_failed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: var(--info-light);
    color: var(--info-primary-dark);
    width: 98%;
    margin: auto;
  }

    .sentiment-bar-message_text_failed {
      background-color: var(--error-light);
      color: var(--error-primary-dark);
    }
}

.sentiment-bar-container {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  margin-top: 2rem;
  width: 97%;
  text-align: center;
  height: 2rem;
  background-color: var(--clr-gray200);
  border-radius: 4px;

  .sentiment-bar-positive, .sentiment-bar-neutral, .sentiment-bar-negative {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    font-weight: 600;
    font-size: .9rem;
    box-sizing: content-box;
    padding: 0 1.6rem;
  }

  .clickable {
    &:hover {
      cursor: pointer;
      filter: saturate(2.5);
    }
  } 

  .sentiment-bar-positive {
    background-color: var(--success-light);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .sentiment-bar-neutral {
    background-color: var(--warning-light);
  }

  .sentiment-bar-negative {
    background-color: var(--error-light);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.sentiment-bar-stats {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 97%;
  margin: auto;
  padding: .5rem 0;
  margin-bottom: 1rem;

  .sentiment-bar-stats-item {
    display: flex;
    align-items: center;
    gap: .5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .8rem;
  }

  .sentiment-bar-stats-color {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 2px;

    &.positive {
      background-color: var(--success-light);
    }

    &.neutral {
      background-color: var(--warning-light);
    }

    &.negative {
      background-color: var(--error-light);
    }
  }
}

.threat-select.error {
  .threat__control.css-1s2u09g-control {
    border-color: red;
  }
}

.addToNarrativeModal {
  margin-bottom: 3rem;
}


.negative_sentiment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem .1rem;
  padding-left: .1rem;
  border-radius: 4px;

  background-color: var(--error-light);

  svg {
    color: var(--error-primary);
  }

  svg path{
    stroke: var(--error-primary)!important;
  }
}

.neutral_sentiment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem .1rem;
  padding-left: .1rem;
  border-radius: 4px;

  background-color: var(--warning-light);

  svg {
    color: var(--warning-primary)!important;
  }

  svg path{
    stroke: var(--warning-primary)!important;
   
  }
}

.positive_sentiment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem .1rem;
  padding-left: .1rem;
  border-radius: 4px;

  background-color: var(--success-light);

  svg {
    color: var(--success-primary)!important;
  }

  svg path{
    stroke: var(--success-primary)!important;
   
  }
}

.widget-container {
  display: flexbox;
  flex-grow: 1;
  width: 100%;
  padding: 0 .5rem;
  border: 2px dashed transparent;
    &:hover {
      cursor: pointer;
      border: 2px dashed var(--clr-gray350);
      border-radius: 8px;
      .report-section__controls {
        display: flex;
      }
    &:has(.widget-hover:hover) {
      border: 2px dashed transparent;

      .report-section__controls {
        display: none;
      }
    }
  }
}

.widget-hover {
  display: flexbox;
  position: relative;
  flex-grow: 1;
  padding: 0;
  border: 2px dashed transparent;
  margin-top: auto;
  margin-bottom: auto;


  .widget-hover-controls {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    
  
  }
  &:hover {
    cursor: pointer;
    border: 2px dashed var(--clr-gray350);
    border-radius: 8px;
    .widget-hover-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.469);
      gap: .5rem;
      backdrop-filter: blur(2px);
      z-index: 100;
    }
  }

  .report-note-container.note-position-top {
    margin-bottom: -4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  .report-note-container.note-position-bottom {
    margin-top: -4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    margin-bottom: 1rem;
  }
}

.widget-settings-container {
  padding: 2rem 1rem;
  border: 2px dashed var(--clr-gray350);
  border-radius: 8px;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  color: var(--clr-gray600);
  margin-bottom: 1rem;

  .widget-settings-controls {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  .widget-settings-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  >form {
    min-width: 60%;
    max-width: 90%;
  }
  >form .form-element {
    > label {
      margin-bottom: .5rem;
      display: block;
      font-size: 1rem;
      font-weight: 600;
    }

    input[type='text'], input[type='number'], input[type='url'], input[type='email'], textarea{
      width: 100%;
      height: 2.5rem;
      font-size: 1rem;
      padding: 0.5rem;
      border: 1px solid var(--clr-gray300);
      border-radius: 4px;
      margin-bottom: 1rem;
    }

    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }

    ul li {
      list-style: none;
      margin-bottom: .5rem;
      label {
        display: inline-block;
        margin-bottom: .5rem;
      }

      input[type='checkbox'] {
        margin-right: .5rem;
      }
    }
     
  }

  > form label{
    margin-bottom: 1rem;
    
  }
}

.widgetLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  background-color: var(--secondary-color);
}

.sampling {
  display: flex;
  align-items: center;
  font-size: .8rem;
  font-weight: 400;
  border: 1px solid var(--clr-gray300);
  border-radius: 4px;
  padding: .2rem;
  gap: .5rem;
  z-index: 15;
  color: var(--clr-gray400);
  background-color: var(--clr-gray150);

  .sampling__value {
    font-weight: 500;
    color: var(--main-color);
  }
}

@media print {
  .widget-container {
    border: none;
    padding: 0;
  }
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  margin: .3rem 0 0 0;
  padding: .3rem 0;
  background-color: var(--clr-gray150);

  .react-datepicker__month-select, .react-datepicker__year-select {
    border: 1px solid var(--clr-gray350);
    border-radius: 3px;
    color: var(--clr-gray500);
  }
}
