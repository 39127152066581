.dropdown-menu-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  .sorting {
    background-color: var(--clr-gray300);
    color: inherit;
    border: 1px solid var(--clr-gray300);
    font-weight: 500;
    padding: 0.6rem 1rem;

    &:hover {
      filter: brightness(105%);
    }
  }

  .active {
    color: inherit;
    background-color: var(--secondary-color);
    border: 1px solid var(--color0);

    svg {
      color: var(--color0);
    }
  }
}

.dropdown-menu-container {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 0.4rem;
  position: absolute;
  background-color: var(--secondary-color);
  box-shadow: var(--box-shadow);
  z-index: 300;
  border-radius: 6px;
  border: 1px solid var(--clr-gray300);
  margin-top: .3rem;
  top: 2.7rem;
  right: 0;
  min-width: 200px;

  .dropdown-menu-sorting-order {
    border-top: 1px solid var(--clr-gray300);
    margin-top: .5rem;
    padding-top: .3rem;

    &:first-child {
      margin-top: .4rem;
    }

    .checked {
      background-color: var(--color0);
      color: var(--secondary-color);

      &:hover {
        background-color: var(--color0);
        color: var(--secondary-color);
      }
    }

    & > li {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1rem;

      :first-child {
        margin-right: .3rem;
      }
      &:hover {
        background-color: rgba(#2463eb, .2);
        cursor: pointer;
      }

    }
  }

  & > ul {

    &:first-child {
      margin-top: .4rem;
    }

    .checked {
      background-color: var(--color0);
      color: var(--secondary-color);

      &:hover {
        background-color: var(--color0);
        color: var(--secondary-color);
      }
    }

    & > li {
      display: flex;
      align-items: center;
      white-space: nowrap;

      :first-child {
        margin-right: .3rem;
      }
      &:hover {
        background-color: rgba(#2463eb, .2);
        cursor: pointer;
      }
    }

    label.dropdown-menu-list-label {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 0;
      color: inherit;
    }
  }

  .dropdown-menu-header {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: .4rem;
    padding: 0 .6rem .6rem .6rem;
    font-weight: 500;
    font-size: .85rem;
    border-bottom: 2px solid var(--clr-gray200);
  }

  ul {
    padding: 0 0rem;
    li {
      padding: .5rem .4rem;

      input {
        margin-right: .3rem;
      }
    }
  }
}

.dropdown-menu-container:not(:has(.dropdown-menu-header)) {
  padding-top: 0;

  .dropdown-menu-sorting-order {
    border-top: none;
    padding-top: 0;
  }
}

.dropdown-menu-wrapper.highlights {
  .dropdown-menu-button-name {
    white-space: nowrap;
    font-size: .95rem;
  }
  .multichooser {
    li {
      display: flex;
      align-items: center;
      gap: .2rem;
    }
    & li > label.dropdown-menu-list-label {
      font-size: .8rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 0;
    }
  }

  .sorting {
    background-color: inherit;
    color: inherit;
    // border: 1px solid var(--clr-gray300);
    border: 1px solid transparent;
    font-weight: 500;
    padding: 0.1rem .5rem;

    &:hover {
      filter: brightness(105%);
      color: var(--color0);

      svg {
        transform: translateY(-1px) scale(1.1) rotate(180deg);
        color: var(--color0);
      }
    }

    svg {
      rotate: 180deg;
      transform: translateY(0) scale(1) rotate(0deg) .2s;
  
    &:hover {
      cursor: pointer;
      transform: translateY(-1px) scale(1.1) rotate(180deg);
    }
    }

  }

  .active {
    svg {
      transform: rotate(180deg);
      color: var(--color0);

      &:hover {
        cursor: pointer;
        transform: translateY(1px) scale(1.1) rotate(0deg);
      }
    }
  }


  .active {
    color: var(--color0);
    // background-color: var(--secondary-color);
    background-color: transparent;
    border: 1px solid transparent;

    svg {
      color: var(--color0);
    }
  }
}

.dropdown-menu-wrapper.export {
  .dropdown-menu-button-name {
    white-space: nowrap;
    font-size: .8rem;
  }

  .sorting {
    padding: .45rem .5rem;
    background-color: white;

    &:hover {
      // filter: brightness(105%);
      // color: var(--color0);

      svg {
        transform: translateY(-1px) scale(1.1) rotate(180deg);
        color: var(--color0);
      }
    }

    svg {
      rotate: 180deg;
      transform: translateY(0) scale(1) rotate(0deg) .2s;
    }
  }
  .sorting:disabled {
    &:hover {
      svg {
        rotate: 0deg;
        transform: translateY(0px) scale(1) rotate(180deg);
        color: white;
      }
    }
  }

  .active {
    svg {
      transform: rotate(180deg);
      color: var(--color0);

      &:hover {
        cursor: pointer;
        transform: translateY(1px) scale(1.1) rotate(0deg);
      }
    }
  }
}

.dropdown-menu-wrapper.chartMenu, .dropdown-menu-wrapper.messageMenu{
  .sorting {
    width: fit-content;
    background-color: var(--secondary-color);
    padding: 0.6rem .7rem .6rem .7rem;
    border: 1px solid var(--clr-gray300);
    // border: 1px solid transparent;

    svg {
      color: var(--clr-gray350);
    }
  }

  .active {
    color: inherit;
    background-color: var(--secondary-color);
    border: 1px solid var(--color0);

    svg {
      color: var(--color0);
    }
  }

  .dropdown-menu-button-name {
    white-space: nowrap;
    font-size: .8rem;
  }
}

.dropdown-menu-wrapper.messageMenu {
  .sorting {
    padding: 0.4rem .6rem .4rem .6rem;

    svg {
      height: 15px;
    }
  }
}

.dropdown-menu-wrapper.only-icon {
  .sorting {
    background-color: transparent;
    color: inherit;
    font-weight: 500;
    padding: 0.75rem .7rem;
    border: 1px solid var(--clr-gray350);

    svg {
      color: var(--clr-gray350);
    }

    &:hover {
      filter: brightness(105%);
      border: 1px solid var(--clr-gray400);

      svg {
        color: var(--clr-gray400);
      }
    }
  }

  .active {
    color: inherit;
    background-color: var(--secondary-color);
    border: 1px solid var(--color0);

    svg {
      color: var(--color0);
    }
  }
}
