.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  min-width: 400px;
  max-width: 450px;
  position: sticky;
  top: 5rem;
  // max-width: 450px;

  .filtersWrapper {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
    background-color: var(--clr-gray150);
    border: 1px solid var(--clr-gray200);
    padding: 0 1rem 1rem 1rem;
  }

  .filtersContainer, .feedMainContainer {
    width: 100%;
    background-color: var(--clr-gray150);
    border: 1px solid var(--clr-gray200);
    border-radius: 6px;
    padding: 1rem;
  }

  .filtersContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    border: none;
    padding: 1rem 0 .5rem 0;

    .filters {
      display: flex;
      flex-direction: row;
      gap: .5rem;
    }

    .calendarWrapper {
      display: flex;
      flex-direction: row;
      gap: .5rem;
      align-items: center;

      .hintIcon {
        color: var(--clr-gray350);
        position: relative;
      }
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    border: 1px solid var(--clr-gray300);
    border-radius: 4px;
  }

  .feedMainContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .feedContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    overflow-x: none;
    height: 70vh;
    padding-right: 4px;

    .noData {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--clr-gray500);
      background-color: var(--secondary-color);
      border-radius: 6px;
      padding: 2rem auto;
      text-align: center;
    }

    .feedLoader {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      background-color: var(--info-light);
    }

    &::-webkit-scrollbar {
      background: var(--secondary-color);
      width: 5px;
      height: 6px;
      
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--clr-gray350);
      border-radius: 5px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background: var(--clr-gray100);
    }
  }
}

.tab {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  padding: .5rem;
  flex-basis: 50%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: var(--color0);
    color: var(--secondary-color);
    border-radius: 4px;

    & > svg {
      color: var(--secondary-color);
    }
  }
}

.tab.active {
  background-color: var(--color0);
  color: var(--secondary-color);
  border-radius: 4px;
  & > svg {
    color: var(--secondary-color);
  }
}

.mergeButton, .deleteButton, .searchButton, .copyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: .4rem;
  border-radius: 4px;
  font-size: .9rem;
  color: var(--clr-gray500);
  background-color: var(--secondary-color);
  transition: background-color 250ms ease, color 250ms ease;
  border: 1px solid var(--clr-gray350);

  // svg {
  //   width: 22px;
  //   height: 22px;
  // }

  &:hover {
    background-color: var(--color0);
    color: var(--secondary-color);
    border: 1px solid var(--color0);
  }
}

.copyButton {
  padding: .6rem;
}

.searchButton,  .searchButtonActive{
  padding: 0 .6rem;
  border: 1px solid var(--clr-gray350);
  background-color: transparent;
  max-height: 42px;
  color: var(--clr-gray350);

  & svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: var(--clr-gray400);
    border: 1px solid var(--clr-gray400);
    background-color: transparent;
  }
}

.searchButtonActive {
  background-color: var(--secondary-color);
  color: var(--color0);
  border: 1px solid var(--color0);
}

.eventsSearch {
  box-shadow: none;
  border-color: var(--clr-gray300);
}

.deleteButton:hover {
  background-color: var(--error-primary);
  color: var(--secondary-color);
  border: 1px solid var(--error-primary);
}

.errorStoriesMerge {
  color: var(--error-primary);
}

.mergeEventsModal {
  :global(.modal-dialog) {
    min-width: 900px;
  }

  .modalForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

.tooltipContent {
  & > p:first-of-type {
    margin-bottom: .3rem;
  }
}



@media only screen and (min-width: 1500px) {
  .mainContainer {
    min-width: 450px;
    max-width: 500px;
  }
}