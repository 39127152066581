.topics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  
  grid-gap: 1rem;
  /* display: flex; */
  /* justify-content: space-between; */
  gap: 1rem;
  flex: 1;
  width: 100%;
}

.topics .topic {
  background: var(--secondary-color);
  color: var(--main-color);
  /* border-radius: 10px; */
  /* box-shadow: 0 2px 10px var(--clr-gray300); */
  margin-bottom: 1rem;
  overflow: hidden;

  border: 1px solid #e7e8e9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

.topic .header {
  padding: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom: 1px solid #e7e8e9;
  color: var(--stroke);
  background: var(--background);
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  padding-right: 20px;
  overflow: hidden;
}

.header__number {
  border: 1px solid var(--stroke);
  border-radius: 4px;
  width: 50px;
  text-align: center;
  font-size: 12px;
  padding: 2px 0;
  line-height: 1;
  margin-right: 1em;
}

.topic .body {
  padding: 0.5rem;
}

.topic .body .body-header {
  margin: 0.2rem;
}

.topic .body .metrics {
  display: flex;
  font-size: 20px;
  flex-wrap: wrap;
}
.topic .percentage {
  padding: 0.5em 0 0.5em 0;
}
.topics .metrics .card-content {
  width: 50%;
  margin-bottom: 0;
}

.metrics .metric {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.3rem;
  margin: 0rem;
  text-overflow: ellipsis;
  padding-right: 20px;
  overflow: hidden;
}

.metrics .metric.impact-score {
  border-radius: 7px;
  border: 1px solid #e7e8e9;

  color: var(--stroke);
  background: var(--background);
}




.metrics .metric .metric-header {
  text-transform: uppercase;
  /* color: #a0a3a6; */
  color: var(--main-color);
  font-size: 10px;
}

/* .metrics .metric .metric-body {
  
} */

.topic tbody td {
  vertical-align: top;
}

.topic tbody tr {
  border: none;
  border-top: 1px solid #e5e7eb;
}

.topic .messages {
  font-size: 0.9rem;
}

.card-content {
  display: flex;
  align-items: center;
}

.card-content__icon {
  width: 48px;
  height: 48px;
  border-radius: 9px;
  background: #eff6ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background: var(--background);
}
.card-content__icon-small {
  width: 36px;
  height: 36px;
}

.overview-topics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.overview-topics .card-wrapper {
  background: var(--secondary-color);
  color: var(--main-color);
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;

  border: 1px solid #e7e8e9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

.overview-topics .card-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.overview-topics .card-content .header {
  font-size: 0.8rem;
  text-transform: uppercase;

  color: #a0a3a6;
  font-size: 11px;
}

.overview-topics .card-content .body {
  font-size: 18px;
}

.list-content h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.color0 {
  --stroke: var(--color0);
  --background: var(--color0l);
}

.color1 {
  --stroke: var(--color1);
  --background: var(--color1l);
}

.color2 {
  --stroke: var(--color2);
  --background: var(--color2l);
}

.color3 {
  --stroke: var(--color3);
  --background: var(--color3l);
}

.card-content__icon path {
  stroke: var(--stroke);
}

@media print {
  .sidebar,
  .controls,
  // .card-circlechartwrapper,
  .card-messageswraper,
  .pagination {
    display: none !important;
  }
  .main-content {
    margin-left: 0;
  }
  main {
    left: 0;
    position: static;
    margin: 0;
    width: auto;
  }
  .card {
    break-inside: avoid;
  }
  .main-content > header {
    display: none;
  }
  .list-content h3 {
    margin-top: 0.5em;
  }
  .report-section {
    break-inside: avoid;
  }
  .breadcrumb span:first-child {
    display: none;
  }
  .breadcrumb span {
    font-size: 3em;
  }
  .page-header {
    margin-bottom: 0;
  }
  .breadcrumb span:before {
    display: none;
  }
}

.chart-select {
  margin-left: auto;
  position: relative;
  min-width: 150px;
}

.trend-chart-controlls button.active {
  background: rgba(35, 55, 55, 0.05);
  color: #2C2E30;
  border: none;
}

.trend-chart-controlls button {
  background: none;
  color: var(--clr-gray350);
  border: none;
}

.trend-chart-controlls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.trend_chart_filters {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.trend-chart-controlls:has(.trend_chart_filters) {
  justify-content: space-between;
}

.trend-chart-controlls__tabs {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.trend-chart-controlls:has(.sampling) {
  justify-content: space-between;
}

.story-index {
  padding: .5rem;
  border-radius: 3px;
}

.story-index.low {
  background: #FCF8DD;
  border: 4px solid #FFFFFF;
  border-radius: 8px;
  font-weight: 500;
}

.story-index.medium {
  background: #FFEEDE;
  border: 4px solid #FFFFFF;
  border-radius: 8px;
  font-weight: 500;
}

.story-index.high {
  background: #FEE2E2;
  border: 4px solid #FFFFFF;
  border-radius: 8px;
  font-weight: 500;
}

.story-index.title {
  padding: 5px;
}

.threats {
  font-size: .9rem;

  .message:hover {
    cursor: pointer;
    color: var(--color0);
  }
}