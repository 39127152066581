
.buttonText {
  white-space: nowrap;
}

.controls {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
}

.searchWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.queryInput {
  flex: 100% 0 0;
  background-color: var(--secondary-color);
  border-radius: .5rem;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
  z-index: 30;
}

.deleteButton {
  background-color: #dc2626;
  color: #fff;
  transition-duration: 250ms;
  transition-timing-function: ease;
  transition-property: color, background-color, border-color, opacity;
  border: 2px solid #fff;

  &:hover {
    background-color: #fff;
    color: #dc2626;
    border-color: #dc2626;
  }

  &:disabled {
    background-color: #dc2626 !important;
    color: #fff;
    border-color: #fff;
    opacity: 0.5;
  }
}

.sortable {
  cursor: pointer;
  user-select: none;
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  grid-gap: 4px;
  align-items: center;
}

.icon {
  vertical-align: middle;
  opacity: 0;
  transition: opacity 300ms ease;

  &.visible {
    opacity: 1;
  }

  &.reversed {
    transform: rotate(180deg);
  }
}

.noData {
  margin: 4rem auto;
  font-weight: 500;
  text-align: center;
}

.modalControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.modalSelects {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  gap: 1rem;

  .chartSelect {
    width: 155px;
  }

  :global(.chartSelect__menu) {
    z-index: 5;
  }
}

:global(.card-body) {
  width: auto !important;
}

.parametersCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--clr-gray200);
  border-radius: 8px;
  background-color: var(--secondary-color);
  box-shadow: var(--box-shadow);
  color: var(--main-color);
  margin-bottom: 3rem;
  padding: 1rem;

  .headerInfoTitle {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .headerInfo {
    .statusWrapper {
      display: inline-flex;
      flex-direction: row;
      gap: .5rem;
      align-items: center;
      font-weight: 500;
      white-space: nowrap;
    }

    .statusWrapper.accepted {
      color: var(--success-medium) !important;
    }
  
   .statusWrapper.pending {
      color: var( --warning-primary-dark) !important;
    }
  
    .statusWrapper .declined {
      color: var(--error-primary) !important;
    }

    .headerTags {
      margin-top: 1.5rem;
    }
    .tagsContainer {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .badge {
      border: 1px solid var(--clr-gray350);
      border-radius: 3px;
      margin-right: .5rem;
      line-height: 1.1rem;
      padding: 3px 5px;
      font-size: .8rem;
      white-space: nowrap;
      font-weight: 500;
      margin-bottom: .2rem;
      color: var(--main-color);
    }
  
    .mark {
      border-radius: 3px;
      padding: 4px 6px;
      font-size: .8rem;
      font-weight: 500;
      white-space: nowrap;
      margin-right: .5rem;
      line-height: 1.1rem;
      margin-bottom: .2rem;
      background-color: var(--clr-gray200);
    }

    .headerList {
      columns: 2;
      -moz-columns: 2;
      -webkit-columns: 2;
      column-gap: 7rem;

      li {
        text-transform: uppercase;
        font-size: .8rem;
        letter-spacing: .5px;

        &:not(:last-child) {
          margin-bottom: .8rem;
        }

        .externalLink {
          display: inline-block;
          height: fit-content;
          vertical-align: baseline;
          margin-left: 5px;

          & svg {
          margin-bottom: -3px;
          }

          &:hover {
          svg {
            color: var(--color0);
            transition: all .2ms;
            transform: translateY(-1px);
          }
          }
        }

        span {
          color: var(--clr-gray350);
          margin-right: .5rem;
        }

        .active {
          color: var(--success-medium);
        }
  
        .inactive {
          color: var(--error-primary);
        }
      }
    }
  }
}


.sources, .messages {
  :global(.text-nowrap) {
    vertical-align: top;
  }

  .headerInfo {
    width: 100%;
    background-color: var(--clr-gray300);
    padding: 1rem;
    font-weight: 600;
    font-size: 1rem;
    margin-top: .5rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    position: sticky;
    left: 0;
    
     td:first-of-type {
      background-color: var(--clr-gray300);
  
      &::after {
        content: '';
        width: 0;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background-color: none;
        box-shadow:none;
  
      }
     }    
  }

  .sourceNameWrapper {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    .platformWrapper {
      svg {
        width: 16px;
        height: 16px;
      }
    }

  }

  .sourceNameContainer {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    line-height: 1;


    span {
      font-weight: 500;

      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        margin-top: -2px;

      }

      a {
        color: var(--clr-gray450);
        font-size: .9rem;

        &:hover {
          color: var(--clr-gray350);
        }
      }

    }

    a {
      font-size: .8rem;
    }
  }

  tr, td {
    border: 1px solid var(--clr-gray300);
  }

  td  {
    font-size: .9rem;
    vertical-align: top;
    width: fit-content;
    white-space: nowrap;

    i {
      font-size: 1.2rem;
    }
  }

  td:first-of-type {
    position: sticky;
    left: -1px;
    background-color: var(--secondary-color);
    border-top: none;
    white-space: wrap;
    min-width: 250px;
    z-index: 2;

    &::after {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--clr-gray300);
      box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);

    }
  }

  a {
    svg {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
  }
  
}


.chartTreeModal {

  :global(.modal-dialog) {
    min-width: 1120px;

    :global(.tabs-container) {
      overflow-x: auto;
    
      :global(.tabs-header-wrapper) {
        position: sticky;
        left: 0;
      }
    
    }
  }

  .tableWrapper {
    width: auto;
  }
  
}

.controls {
  position: relative;
  :global(.btn-primary) {
    display: flex;
    justify-content: center;
    min-height: 40px;
    min-width: 54px;
  }
}

.view {
  margin-left: 1rem;
  vertical-align: middle;

  svg {
    stroke: var(--clr-gray350);
    transition: all .2s;

    &:hover {
      stroke: var(--color0);
      transform: translateY(-1px);
    }
  }
}

.sortingControls {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  gap: 1.5rem;

  .search {
    flex: 1 0 0;
  }
}

.bulkButtonsWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.08) inset;
  }
  

  button {
    background-color: var(--secondary-color);
    border: 1px solid var(--clr-gray300);
    color: inherit;
    border-radius: 0;
    border-right: none;
    transition: all .2s;
    font-size: .9rem;

    .clear {
      display: flex;
      align-items: center;
      color: var(--clr-gray350);
      
      &:hover {
        color: var(--color0);
        transform: scale(1.2);
        cursor: pointer;
      } 
    }

    &:hover {
      color: var(--color0);
      transform: translateY(-1px);
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.06);
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: var(--color0);
      transform: translateY(0);
      padding-right: .5rem;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid var(--clr-gray300);

      &:hover {
        color: var(--color2);
      }

      &:disabled {
        display: none;
      }
    }
  }
  
}

.bulkButtonsWrapper.floating {
  position:fixed;
  right: 4rem;
  top: 100px;
  z-index: 1000;
}

.cardHeader {
  justify-content: end !important;
}

.cardControls {
  display: flex;
  gap: 1rem;
}

.errorModal {
  .errorModalText {
    margin: 1rem .5rem;
  }
  ul {
    margin: 1.5rem 1rem;
    
    li {
      list-style-type: disc;
      list-style-position: inside;
      &:first-child {
        margin-top: .5rem;
      }
      margin-bottom: .7rem;

      span {
        font-weight: 600;
      }
    }
  }
}

.errorStoriesMerge {
    margin: 1rem 0;
    color: var(--error-primary);
}

.mergeStoriesModal {
  .formElement {
    label {
      display: block;
      margin-bottom: .5rem;
    }

  margin-bottom: 1.5rem;

  input[type='text'],
    textarea {
      width: 100%;
      font-size: 1.3rem;
      padding: 0.5rem;
      border: 1px solid var(--clr-gray300);

      &:required:invalid {
        border-color: red;
      }
    }
  }
}

.descriptionCard {
  background: var(--secondary-color);
  color: var(--main-color);
  margin-bottom: 3rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--clr-gray200);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  & > ul li{
    list-style-type: disc!important;
    list-style-position: inside;
  }
  
  & > ol li{
    list-style-type: decimal!important;
    list-style-position: inside;
  }
  
  p:not(:first-child) {
    margin-top: .7rem;
  }
}

:global(.report-section) {
  .descriptionCard {
    margin-bottom: 1rem;
  }
}

.extendedMessages {
  td:first-child {
      width: 100% !important;
  }
}


.extendedMessage {
  margin-left: 1rem;
  font-size: 1rem;
}

.originalTextBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  background-color: var(--clr-gray150);
  border-left: 6px solid var(--clr-gray300);
  padding: 1rem 1rem 1rem .5rem;
  margin-left: 11px;
  margin-right: 4rem;
  border-radius: 4px;

  .loaderContainer {
    width: 100%;
  }

  &:has(.loaderContainer) {
   align-items: center;
  }
  
  .extendedMessage {
    margin-left: 0;
    font-size: 1rem;
  }
}


.imagesContainer {
  
  margin-left: .8rem;
}

// Breakdows
.breakdownsContainer {
  display: flex;
  flex-direction: column;
  

  & > h2 {
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    font-weight: 600;
    font-size: 1.3rem;
  }
}

.widdgetLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.widgetLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  background-color: var(--secondary-color);
  margin-top: 2rem;
}
.chartWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.breakdownsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  margin-bottom: 1rem;
}

.widgetRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.widgetStats, .widgetTop {
  h5 {
    display: block;
    background-color: var(--clr-gray150);
    padding: .5rem 1.5rem;
    margin-bottom: 1rem;
  }

  @media print {
    h5 {
      -webkit-print-color-adjust:exact !important;
      print-color-adjust:exact !important;
    }
  }

  .details {
    color: var(--main-color);
  }

  // padding: 1rem 1rem;
  border-left: 1px solid var(--clr-gray300);
}

.widgetTop {
  flex-grow: 2;

  h5 {
    margin-bottom: 1rem;
  }
}

.widgetStats {
  flex-basis: 20%;
  justify-content: center;
}

.widgetSummary {
  min-width: 12%;
  justify-content: center;
  // padding: 1rem 1rem 1.5rem 0;

  h5 {
    display: block;
    background-color: var(--clr-gray150);
    padding: .5rem 1.5rem;
    margin-bottom: 1.9rem;
  }

  @media print {
    h5 {
      -webkit-print-color-adjust:exact !important;
      print-color-adjust:exact !important;
    }
  }

  h2 {
    // margin-bottom: 1.5rem;
    // margin-left: 1.5rem;
    margin: 0 1.5rem 1.5rem 1.5rem;
  }

  p {
    font-weight: 600;
    font-size: .9rem;
    // text-transform: uppercase;
    color: var(--clr-gray400);
    letter-spacing: .4px;
    margin: 0 1.5rem 2rem 1.5rem;
    &:first-of-type {
      margin-bottom: .3rem;
    }
  }
}

.breakdownsListMain, .breakdownsExtendedList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
    .details {
      color: var(--main-color);
      line-height: 1.3rem;
    }

  .listItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3rem;
    gap: .5rem;
    color: var(--main-color);

    .details {
      color: var(--main-color);
      line-height: 1.3rem;
    }

    .info {
      display: flex;
      flex-direction: row;
      gap: .5rem;
      align-content: center;
      justify-content: center;

      .verified {
        display: inline;
        margin-left: 5px;
        svg {
          width: 15px;
          height: 15px;
        }
      }

      .title {
        white-space: nowrap;
        &> a {
          color: var(--main-color);
        }
        
        &:hover {
          cursor: pointer;
          color: var(--clr-gray350);
        }
      }

      span {
        display: flex;
        align-items: center;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .count {
      font-weight: 600;
    }
  }
}

.affiliationsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .affilationCountry {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    color: var(--main-color);
    border-bottom: 1px solid green;

    span {
      display: flex;
      align-items: center;

      svg {
        height: 20px;
        width: 20px;
      }
    }

  }
}

.topSourcesContainer {  
  display: grid;
  overflow: hidden;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-auto-rows: auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-items: start;
  align-items: start;
  justify-content: start;
  grid-template-areas:
    "nameHeader audienceHeader countryHeader messagesHeader"
    "name audience country messages";

  .nameHeader, .audienceHeader, .countryHeader, .messagesHeader {
    padding: .5rem 1.5rem;
    background-color: var(--clr-gray150);
    width: 100%;
    font-weight: 500;
  }

  @media print {
    .nameHeader, .audienceHeader, .countryHeader, .messagesHeader {
      -webkit-print-color-adjust:exact !important;
      print-color-adjust:exact !important;
    }
  }

  .name, .audience, .country, .messages {
    width: 100%;
    height: 100%;
  }

  .nameHeader {
    grid-area: nameHeader;
    border-right: 1px solid  var(--clr-gray300); 
  }
  
  .audienceHeader {
    grid-area: audienceHeader;
    border-right: 1px solid  var(--clr-gray300); 
  } 

  .countryHeader {
    grid-area: countryHeader;
    border-right: 1px solid  var(--clr-gray300); 
  } 

  .messagesHeader {
    grid-area: messagesHeader;
  } 

  .name {
    grid-area: name;
    border-right: 1px solid  var(--clr-gray300); 
  }

  .audience {
    grid-area: audience;
    border-right: 1px solid  var(--clr-gray300); 
  } 

  .country {
    grid-area: country;
    border-right: 1px solid  var(--clr-gray300); 
  } 

  .messages {
    grid-area: messages;
    border-right: 1px solid  var(--clr-gray300); 
  } 
}

.topTagsContainer {
  display: grid;
  overflow: hidden;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-auto-rows: auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-items: start;
  align-items: start;
  justify-content: start;
  grid-template-areas:
    "nameHeader sourcesHeader messagesHeader"
    "name sources messages";

  .nameHeader, .sourcesHeader, .messagesHeader {
    padding: .5rem 1.5rem;
    background-color: var(--clr-gray150);
    width: 100%;
    font-weight: 500;
  }

  @media print {
    .nameHeader, .sourcesHeader, .messagesHeader {
      -webkit-print-color-adjust:exact !important;
      print-color-adjust:exact !important;
    }
  }

  .name, .sources, .messages {
    width: 100%;
    height: 100%;
  }

  .nameHeader {
    grid-area: nameHeader;
    border-right: 1px solid  var(--clr-gray300); 
  }

  .sourcesHeader {
    grid-area: sourcesHeader;
    border-right: 1px solid  var(--clr-gray300); 
  }

  .messagesHeader {
    grid-area: messagesHeader;
  } 

  .name {
    grid-area: name;
    border-right: 1px solid  var(--clr-gray300); 
  }

  .sources {
    grid-area: sources;
    border-right: 1px solid  var(--clr-gray300); 
  }

  .messages {
    grid-area: messages;
  }
  
}

@media only screen and (max-width: 1220px) {
  .breakdownsRow {
    flex-direction: column;
  }

  .widgetRow {
    flex-direction: column;
  }

  .widgetStats, .widgetTop {
    h5 {
      margin-bottom: .5rem;
    }
  
    // padding: 1rem 1rem;
    border-bottom: 1px solid var(--clr-gray300);
    border-left: none;
  }
  
  .widgetTop {
    flex-grow: 1;
    padding: 0 0 1rem 0;
  }
  
  .widgetStats {
    flex-basis: 100%;
    justify-content: center;
    padding: 0 0 1rem 0;
  }
  
  .widgetSummary {
    min-width: 100%;
    justify-content: center;
    border-bottom: 1px solid var(--clr-gray300);
    padding-bottom: 1.5rem;

  
    h5 {
      margin-bottom: 1.5rem;
    }
  
    h2 {
      margin-bottom: 1rem
    }
  
    p {
      display: inline;
      font-weight: 700;
      font-size: .8rem;
      text-transform: uppercase;
      color: var(--clr-gray400);
      letter-spacing: .4px;
      margin-right: .3rem;
      
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
}

.storiesModal {
  :global(.modal-footer) {
    padding: 0 0 1rem 0;
  }
}

.modalControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;

  .modalActions {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    justify-content: end;
    border-top: 1px solid var(--clr-gray300);
    padding-top: 2rem;
    padding: 1.5rem 1.5rem 0 1.5rem;

    button {
      & > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.createStories, .createStoriesFailed, .createStoriesMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  background-color: var(--info-light);
  color: var(--info-primary-dark);
  padding: 1rem;
  width: 100%;
}
.createStories {
  background-color: transparent;
  color: transparent;
}

.createStoriesFailed {
  background-color: var(--error-light);
  color: var(--error-primary-dark);
}

.progressBarMessage {
  margin-bottom: 1rem;
}


.statusBarContainer {
  background: var(--secondary-color);
  color: var(--main-color);
  margin-bottom: 3rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--clr-gray200);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .progressBarStatus {
    margin-top: 1rem;
  }

  .progressBarStatus.failed {
    color: var(--color2);
  }

  .progressBarWrapper {
    width: 100%;
    height: 2rem;
    background-color: var(--clr-gray200);
    border-radius: 4px;
    margin: 1rem 0 2rem 0;
    
  }

  .progressBar {
    height: 2rem;
    background-color: var(--color0);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    font-weight: 600;
    border-radius: 4px;
  }

  .progressBar.emptyBar {
    background-color: var(--clr-gray200);
    color: var(--color2);
  }
}

//PDF export styles

.exportContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;

  .pdfPageContainer {
    padding: 1rem;
    border: 1px solid var(--clr-gray300);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 965px;
    // flex-basis: 79%;
    background-color: #FBFBFC;

    .exportHeaderCard {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      background-color: var(--secondary-color);
      box-shadow: var(--box-shadow);
      border-radius: 8px;
      border: 1px solid var(--clr-gray200);
      margin-bottom: 3rem;
      justify-content: center;
      align-items: center;

      .headerInfoTitle {
        text-align: center;
      }

      .logoContainer {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
      }
    }

    .breakdownsRow {
      .widgetSummary {
        div {
          h2 {
            font-size: 16px;
          }

          p {
            font-size: 10px;
          
          }
        }
      }
    }

    

    .widgetSummary, .widgetStats, .widgetTop {
      .title, .count {
        font-size: 12px;
      }
      h5 {
        font-size: 9px;
      }
    }

    .widgetTop {
      .breakdownsListMain {
        .listItem {
          font-size: 12px;
        }
      }
    }

    .affiliationsWrapper {
      .listItem, .details, .affilationCountry, .title {
        font-size: 11px;
        line-height: 1.2rem;
      }
    }
  }

  .pdfSettingsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 200px;
    // border: 1px solid var(--clr-gray200);
    // background-color: var(--secondary-color);
    position: sticky;
    height: fit-content;
    top: 4rem;
  }
}



@media only screen and (max-width: 1500px) {
  .bulkButtonsWrapper {
    button {
      font-size: .8rem;
    }
  }

  .exportContainer {
    justify-content: space-between;

    .pdfPageContainer {
      width: 900px;
    }
  }
  
}

@media print {
  :global(.sidebar),
  :global(.controls),
  // :global(.card-circlechartwrapper),
  :global(.card-messageswraper),
  :global(.pagination) {
    display: none !important;
  }
  :global(.card .card-header) {
    display: none !important;
  }
  :global(.main-content) {
    margin-left: 0;
  }

  :global(.card-circlechartwrapper) {
    display: flex !important;
  }
  :global(main) {
    left: 0 !important;
    position: static !important;
    margin: 0 !important;
    width: auto !important;
  }
  :global(.card) {
    break-inside: avoid;
  }
  :global(.open-message-popup) {
    display: none;
  }
  :global(.message-controlls) {
    display: none;
  }
  :global(.main-content > header) {
    display: none;
  }
  :global(.list-content h3) {
    margin-top: 0.5em;
  }
  :global(.report-section) {
    break-inside: avoid;
  }
  :global(.breadcrumb span:first-child) {
    display: none;
  }
  :global(.breadcrumb span) {
    font-size: 2rem;
  }
  :global(.page-header) {
    margin-bottom: 0;
  }
  :global(.breadcrumb span:before) {
    display: none;
  }

  :global(.message-distribution-header) {
    display: none;
  }

  :global(.tabs-container .tabs-header-wrapper) {
    display: none;
  }
  :global(.narrative-search-form-wrapper) {
    display: none;
  }
  :global(.sentiment-bar-positive) {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
  :global(.sentiment-bar-neutral) {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
  :global(.sentiment-bar-negative) {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
  :global(.list-content) {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }

  .breakdownsContainer {
    filter: opacity(1);
    -webkit-filter:opacity(1);
  }

  .pdfPageContainer {
    width: 1100px !important;
    border: none !important;
    background-color: var(--secondary-color) !important;

    h3, h2 {
      break-after: avoid;
    }
  }

  .pdfSettingsContainer {
    display: none !important;
  }

  .topTagsContainer, .topSourcesContainer {
    break-inside: avoid;
  }

}


